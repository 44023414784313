.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #252322;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navbar{
  background-color: #1E1C1B;
  padding: 1rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}

p{
color: white;  
}

.slider-container {
  width: 100%;
  height: 100%;
}

.carousel-style {
  width: 100%;
  margin: auto;
}

.carousel .thumb {
transition: border .15s ease-in;
display: inline-block;
margin-right: 6px;
white-space: nowrap;
overflow: hidden;
border: 0px solid black;
padding: 2px;
}

.carousel .thumb.selected, .carousel .thumb:hover {
border: 3px solid #333;
}

.container{
max-width: 85%;
}

#link
{
  color: rgb(188, 156, 88);
}

#link:hover
{
  color: white;
  background-color: rgb(188, 156, 88);
}

#FreeEstimateButton
{
  color: rgb(188, 156, 88);  
  border-color: rgb(188, 156, 88);
}

#FreeEstimateButton:hover
{
  color: white;
  background-color: rgb(188, 156, 88);
}

#SubmitButton
{
  color: rgb(188, 156, 88);  
  border-color: rgb(188, 156, 88);
}

#SubmitButton:hover
{
  color: white;
  background-color: rgb(188, 156, 88);
}

.Email-Text
{
  text-align: center;
  color: rgb(188, 156, 88);
  margin-bottom: 2rem;
  word-break: break-all;
}

.PhoneNumber
{
  text-align: center;
  color: rgb(188, 156, 88); 
  margin-bottom: 2rem;
}

.ContactUs
{
  text-align: center;
  color: rgb(188, 156, 88);   
  margin-bottom: 1rem;
}

.Footer-Heading-h5
{
  color: rgb(188, 156, 88); 
  text-decoration: underline;
}

.Footer-Heading-h6
{
  color: rgb(188, 156, 88);
  word-break: break-all;
}

.Link-Override {
  color: rgb(188, 156, 88);;
  text-decoration: none;
}

.Link-Override:hover
{
  text-decoration: underline;
}

.FormLabel
{
  color: rgb(188, 156, 88);
  text-align: center;
}

.About-Text
{
  color: rgb(188, 156, 88);
  font-size: 1.4rem;
  text-align: center;
}

.About-Heading
{
  color: rgb(188, 156, 88);
  text-align: center;
  text-decoration: underline;
}

.Our-Work-Heading
{
  color: rgb(188, 156, 88);
}

.OurWorkImage:hover 
{
  -webkit-filter: brightness(50%);
  filter: brightness(50%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.HoverImage
{
  position: absolute;
  color: rgba(255, 255, 255, 0.55);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  pointer-events: none;
}

.HomePageTextOverlay
{
  position: absolute;
  text-align: center;
  color: rgb(188, 156, 88);
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Services-Text
{
  color: rgb(188, 156, 88);
  font-size: 1.4rem;
  text-align: center;
}

.Services-Heading
{
  color: rgb(188, 156, 88);
  text-align: center;
  text-decoration: underline;
}

.FormInput
{
  background-color: #757575 !important;
  border: .05rem solid black !important;
}

.SubmitButton
{
  text-align: center; 
  display: flex; 
  align-items: center;
  justify-content: center;
  margin-right: auto; 
  margin-left: auto;
  width: "30%";
  background: "#757575";
  border-color: "black";
  color: "black";
}